import { Input, InputNumber, Form, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import locale from 'antd/es/date-picker/locale/zh_CN';
import { useEffect, useRef, useState } from "react";
import Ajv from 'ajv';
import ace from 'brace';
import { JsonEditor as Editor } from 'jsoneditor-react-bokzor';
const ajv = new Ajv({ allErrors: true, verbose: true });

export function FormScreenGroup({ screen, form, versions }: any) {
    const [minVersionSearch, setMinVersionSearch] = useState<any>(null)
    const [maxVersionSearch, maxMinVersionSearch] = useState<any>(null)
    // json编辑器的关联
    const jsonEditorRef = useRef()

    // 搜索最小版本，如果没有则自行添加
    const handleSearchMinVersion = (v: any) => {
        if (Object.keys(versions).indexOf(v) === -1)
            setMinVersionSearch(v)
        else
            setMinVersionSearch(null)
    }

    // 搜索最小版本，如果没有则自行添加
    const handleSearchMaxVersion = (v: any) => {
        if (Object.keys(versions).indexOf(v) === -1)
            maxMinVersionSearch(v)
        else
            maxMinVersionSearch(null)
    }

    useEffect(() => {
        console.log(screen)
        form.setFieldsValue({ ...screen, begin_and_end: [dayjs(screen?.begin_at).add(8, "hour"), dayjs(screen?.end_at).add(8, "hour")] })

        return () => {

        }
    }, [])


    return <Form form={form} name="basic" initialValues={screen} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} autoComplete="off">
        <Form.Item label="开屏名" name="name" rules={[{ required: true, message: '必填' }]}>
            <Input placeholder="标题，运维可见" />
        </Form.Item>
        <Form.Item label="最小版本" name="min_version_code" rules={[{ required: true, message: '必填' }]} help="这里选中或者自定义的是Version Code不是Version Name">
            <Select onSearch={handleSearchMinVersion} showSearch>
                {Object.keys(versions).map((key: any) => <Select.Option key={key}>{versions[key]} <span style={{ color: "silver" }}>{key}</span></Select.Option>)}
                {!!minVersionSearch && <Select.Option key={minVersionSearch}>{minVersionSearch}</Select.Option>}
            </Select>
        </Form.Item>
        <Form.Item label="最大版本" name="max_version_code" rules={[{ required: true, message: '必填' }]} help="这里选中或者自定义的是Version Code不是Version Name">
            <Select onSearch={handleSearchMaxVersion} showSearch>
                {Object.keys(versions).map((key: any) => <Select.Option key={key}>{versions[key]}  <span style={{ color: "silver" }}>{key}</span></Select.Option>)}
                {!!maxVersionSearch && <Select.Option key={maxVersionSearch}>{maxVersionSearch}</Select.Option>}
            </Select>
        </Form.Item>
        <Form.Item label="有效时间" name="begin_and_end" rules={[{ required: true, message: '必填' }]}>
            <DatePicker.RangePicker format="YYYY-MM-DD HH:mm:ss" locale={locale} />
        </Form.Item>

        <Form.Item label="可跳过延迟" name="remain" rules={[{ required: true, message: '必填' }]} >
            <InputNumber min={1} addonAfter="秒" />
        </Form.Item>

        <Form.Item label="最大展示次数" name="max_display_times" rules={[{ required: true, message: '必填' }]} >
            <InputNumber min={1} addonAfter="次" />
        </Form.Item>

        <Form.Item label="行为类型" name={["action", "type"]} rules={[{ required: true, message: '必填' }]} >
            <Select >
                <Select.Option value="app_router">APP路由</Select.Option>
                <Select.Option value="url">URL</Select.Option>
                <Select.Option value="wechat_miniprogram">微信小程序</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item label="行为参数" name={["action", "data"]} initialValue={screen?.action?.data && JSON.stringify(screen?.action?.data) || {}} rules={[{ required: true, message: '必填' }]} help="由于每个APP适配的参数不一样，所以这里是自定义的格式">
            <Editor ref={jsonEditorRef} ace={ace} mode="code" ajv={ajv} language="zh-CN" />
        </Form.Item>
    </Form>
}